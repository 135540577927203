import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyCAp5vNctF1XABB-CzoOlVzusRtLDbnAW8",
  authDomain: "policewriting1202.firebaseapp.com",
  projectId: "policewriting1202",
  storageBucket: "policewriting1202.appspot.com",
  messagingSenderId: "857440424582",
  appId: "1:857440424582:web:978ea4e4421124d00a3707",
  measurementId: "G-R6QNWJLPCB"
};

//  DEV ** Remove for Production **
// const firestoreSettings = {
//   host: "localhost:8080",
//   ssl: false,
//   experimentalForceLongPolling: true,
// }

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// reCAPTCHA v3
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LcUkyIkAAAAAJa2QBx0ypYUVXzgXGF8w2w6mnRB'),
  isTokenAutoRefreshEnabled: true
});

const db = getFirestore();
const functions = getFunctions(app);
// connectFirestoreEmulator(db, 'localhost', 8082);
// connectFunctionsEmulator(functions, "localhost", 5001);
const analytics = getAnalytics(app);

export {db, functions};