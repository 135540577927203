import React from 'react';
import {
  Admin,
  Article,
  Articles,
  IndexView,
  Downloads,
  SigninSimple,
  ReadingList,
  Workshop,
}
from 'views';

const routes = [    
  {
    path: '/reading-list',
    renderer: (params = {}) => <ReadingList {...params} />,
  },
  // {
  //   path: '/downloads',
  //   renderer: (params = {}) => <Downloads {...params} />,
  // },
  {
    path: '/articles',
    renderer: (params = {}) => <Articles {...params} />,
  },
  {
    path: '/article/:slug',
    renderer: (params = {}) => <Article {...params} />,
  },
  {
    path: '/workshop/:title',
    renderer: (params = {}) => <Workshop {...params} />,
  },
  {
    path: '/admin',
    renderer: (params = {}) => <Admin {...params} />,
  },
  {
    path: '/login',
    renderer: (params = {}) => <SigninSimple {...params} />,
  },
  {
    path: '/',
    renderer: (params = {}) => <IndexView {...params} />,
  },
  ];
  
  export default routes;