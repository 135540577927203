import React from 'react';
import { Routes as ReactRoutes, Route, Navigate } from 'react-router-dom';
import viewsRoutes from 'views/routes';
import NotFound from 'views/NotFound'


const Routes = () => {
    return (
      <ReactRoutes>
        {viewsRoutes.map((item, i) => (
          <Route key={i} path={item.path} element={item.renderer()} />
        ))}
        <Route path="*" element={<NotFound />} />
        {/* <Route path="*" element={<Navigate replace to="/404" />} /> */}
      </ReactRoutes>
    );
  };
  
  export default Routes;