/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import Container from 'components/Container';

const mock = [
  {
    feedback:
      'The course was well organized and engaging. The hands on work allowed me to understand and incorporate the concepts learned in the course.',
    name: 'Patrol, 2–5 Years',
    title: '6 Rules of Effective Police Report Writing',
  },
  {
    feedback:
      'I really enjoyed the information provided. Even after writing professionally for so long, there was information to learn and take away.',
    name: 'Administrator, 15+ Years',
    title: 'Executive Writing',
  },
  {
    feedback:
      'The course was great, guides and reference materials are very useful, course information was easy to follow',
    name: 'Corrections, Booking/Processing, 5–10 Years',
    title: '6 Rules of Effective Police Report Writing',
  },
  {
    feedback:
      'This was EXACTLY what I wanted!!! A class focused on structure and style of writing and really helped me to improve my writing and thought process!! Instructor was born to teach this (I mean that in the best way!) Engaging, knowledgeable, and enthusiastic!!',
    name: 'Training/admin, 5-10 Years',
    title: 'Executive Writing',
  },
  {
    feedback: 'Amazing class, the instructor is very professional, always prepared, and very supportive regardless of your writing skills.',
    name: 'Patrol, 0–1 Years',
    title: '6 Rules of Effective Police Report Writing',
  },
  {
    feedback:
      'This is one of the best writing course I have taken.',
    name: 'Commander, Patrol Operations, 15+ Years',
    title: 'Executive Writing',
  },
  
];

const ReviewsWithBorderedGridLayout = () => {
  const theme = useTheme();

  return (
    <Container>
      <Box>
        <Box marginBottom={4}>
          <Box display={'flex'} justifyContent={'center'}>
            {[1, 2, 3, 4, 5].map((item) => (
              <Box key={item} color={theme.palette.secondary.main}>
                <svg
                  width={18}
                  height={18}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
              </Box>
            ))}
          </Box>
          <Typography
            variant="h4"
            align={'center'}
            gutterBottom
            sx={{
              fontWeight: 700,
              marginTop: theme.spacing(1),
            }}
          >
            What our workshop participants are saying
          </Typography>
          <Typography variant="h6" align={'center'} color={'text.secondary'}>

          </Typography>
        </Box>
        <Grid container spacing={2}>
          {mock.map((item, i) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={i}
              sx={{
                border: 0,
                [theme.breakpoints.up('md')]: {
                  borderRight: `1px solid ${theme.palette.alternate.dark}`,
                  '&:nth-of-type(2n)': {
                    borderRight: `1px solid ${theme.palette.alternate.dark}`,
                  },
                  '&:nth-of-type(-n+4)': {
                    borderBottom: 0,
                  },
                  '&:nth-of-type(3n)': {
                    borderRight: 0,
                  },
                  '&:nth-of-type(-n+3)': {
                    borderBottom: `1px solid ${theme.palette.alternate.dark}`,
                  },
                },
              }}
            >
              <Box p={2}>
              <Typography color="text.secondary">{item.feedback}</Typography>
                <Box marginTop={2}>
                  <ListItem component="div" disableGutters sx={{ padding: 0 }}>
                    <ListItemText
                      sx={{ margin: 0 }}
                      primary={item.name}
                      secondary={item.title}
                    />
                  </ListItem>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default ReviewsWithBorderedGridLayout;
