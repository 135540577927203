/* eslint-disable react/no-unescaped-entities */
import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';

const Summary = (props) => {
  const theme = useTheme();

  return (
    <Box>
      <Box>
        <Box marginBottom={1}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 700,
              mb:2,
            }}
          >
            About the Workshop
          </Typography>
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={11}>
          <Typography
            component={'p'}
            variant={'body1'}
            gutterBottom
            sx={{ fontWeight: 'medium', opacity:.85, fontSize:'1.05em' }}
          >
            <div dangerouslySetInnerHTML={{__html: props.summary}}/>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Summary;
