/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import clock from '../../../../../../assets/images/clock.svg';
import docDogEar from '../../../../../../assets/images/doc-dog-ear.svg';
import journalCheck from '../../../../../../assets/images/journal-check.svg';
import pen from '../../../../../../assets/images/pen.svg';
import person from '../../../../../../assets/images/person.svg';
import Stars from './components/Stars';
import ClassFeature from './components/ClassFeature';
import { HelpOutline } from '@mui/icons-material';

const Details = (props) => {
  const theme = useTheme();
  
  return (
    <Box>
      <Box
        padding={{ xs: 3, sm: 4 }}
        width={1}
        component={Card}
        boxShadow={1}
        marginBottom={4}
      >
        { props.details?.overallRating ? <Stars rating={props.details?.overallRating} /> : "" }
        <Box marginY={'25px'}>
          <Typography
            variant="h6"
            fontWeight="700"
            >
            Intended Audience
          </Typography>
          <Box paddingLeft={2}>
            <Typography
              sx={{
                lineHeight:'1.5em',
                opacity: '.85'
              }}
              >
                {props.details?.audience?.map((name,i)=>(
                 <>
                 &bull; {name}<br />
                 </>
                ))}
            </Typography>
          </Box>
        </Box>
        { props.details?.length ? <ClassFeature icon={clock} heading="Length" title={props.details?.length} /> : "" }
        { props.details?.format ? <ClassFeature icon={person} heading="Format" title={props.details?.format} /> : "" }
        { props.details?.writingExercises ? <ClassFeature icon={pen} title="Writing Exercises" /> : "" }
        { props.details?.writtenFeedback ? <ClassFeature icon={docDogEar} title="Written Feedback" /> :"" }
        { props.details?.quickReference ? <ClassFeature icon={journalCheck} title="Quick-Reference Materials" /> : "" }
      </Box>
    </Box>
  );
};

export default Details;
