import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Link from '@mui/material/Link';
import CardMedia from '@mui/material/CardMedia';
import simplySaid from '../../../../assets/images/book-simply-said.png';
import betterBusiness from '../../../../assets/images/book-better-business.png';
import tenSteps from '../../../../assets/images/book-10-steps.png';
import associatedPress from '../../../../assets/images/book-associated-press.png';
import blueBook from '../../../../assets/images/book-blue-book.png';
import chicagoManual from '../../../../assets/images/book-chicago-manual.png';
import collegiateDictionary from '../../../../assets/images/book-collegiate-dictionary.png';
import dreyersEnglish from '../../../../assets/images/book-dreyers-english.png';
import eatsShoots from '../../../../assets/images/book-eats-shoots.png';
import elementsStyle from '../../../../assets/images/book-elements-style.png';
import greggReference from '../../../../assets/images/book-gregg-reference.png';
import randomHouse from '../../../../assets/images/book-random-house.png';
import strategicCommunication from '../../../../assets/images/book-strategic-communication.jpeg';
import technicalCommunications from '../../../../assets/images/book-technical-communications.jpeg';
import technicalWriting from '../../../../assets/images/book-technical-writing.jpeg';
import woeIs from '../../../../assets/images/book-woe-is.png';

import { useTheme } from '@mui/material/styles';

import Container from 'components/Container';

const books = [
    {
        sectionTitle: 'Business Writing',
        books: [
            {
                media: tenSteps,
                title: '10 Steps to Successful Business Writing',
                author: 'Jack E. Appleman',
                link: 'https://amzn.to/3VPQijv',
              },
            {
                media: betterBusiness,
                title: 'HBR Guide to Better Business Writing',
                author: 'Bryan A. Garner',
                link: 'https://amzn.to/3WQaZ0b',
                isFeatured: true,
              },
              {
                media: simplySaid,
                title: 'Simply Said: Communicating Better at Work and Beyond',
                author: 'Jay Sullivan',
                link: 'https://amzn.to/3VJctI7',
              },
        ]
    },
    {
        sectionTitle: 'Technical Writing',
        books: [
            {
                media: strategicCommunication,
                title: 'Writing for Strategic Communication Industries',
                author: 'Jasmine Roberts',
                link: 'https://ohiostate.pressbooks.pub/stratcommwriting/',
            },
            {
                media: technicalCommunications,
                title: 'A Guide to Technical Communications: Strategies & Applications',
                author: 'Lynn Hall & Leah Wahlin',
                link: 'https://ohiostate.pressbooks.pub/engrtechcomm/',
            },
            {
                media: technicalWriting,
                title: 'Technical Writing',
                author: 'Michele DeSilva, et al.',
                link: 'https://openoregon.pressbooks.pub/technicalwriting/',
            },            
        ]
    },
    {
        sectionTitle: 'Grammar',
        books: [
            {
                media: dreyersEnglish,
                title: 'Dreyer\'s English: An Utterly Correct Guide to Clarity and Style',
                author: 'Benjamin Dreyer',
                link: 'https://amzn.to/3Cms2yT',
                isFeatured: true,
            },
            {
                media: eatsShoots,
                title: 'Eats, Shoots & Leaves: The Zero Tolerance Approach to Punctuation',
                author: 'Lynne Truss',
                link: 'https://amzn.to/3QbuJZK',
                isFeatured: false,
            },
            {
                media: woeIs,
                title: 'Woe Is I: The Grammarphobe\'s Guide to Better English in Plain English',
                author: 'Patricia T. O\'Conner',
                link: 'https://amzn.to/3WVMU7U',
                isFeatured: false,
            },
        ]
    },
    {
        sectionTitle: 'Grammar Reference',
        books: [
            {
                media: blueBook,
                title: 'The Blue Book of Grammar and Punctuation: An Easy-to-Use Guide with Clear Rules, Real-World Examples, and Reproducible Quizzes',
                author: 'Lester Kaufman',
                link: 'https://amzn.to/3jQG2dN',
                isFeatured: true,
              },
              {
                media: greggReference,
                title: 'The Gregg Reference Manual: A Manual of Style, Grammar, Usage, and Formatting',
                author: 'William Sabin',
                link: 'https://amzn.to/3Qe5aHm',
                isFeatured: false,
              },
              {
                media: randomHouse,
                title: 'Random House Webster\'s Grammar, Usage, and Punctuation',
                author: 'Random House',
                link: 'https://amzn.to/3Gjcc9r',
                isFeatured: false,
              },
              
              
        ]
    },
    {
        sectionTitle: 'Reference',
        books: [
            {
                media: collegiateDictionary,
                title: 'Merriam-Webster\'s Collegiate Dictionary, 11th Edition',
                author: 'Merriam-Webster',
                link: 'https://amzn.to/3Z7G6G6',
                isFeatured: true,
            },
        ]
    },
    {
        sectionTitle: 'Style Guides',
        books: [
            {
                media: associatedPress,
                title: 'The Associated Press Stylebook',
                author: 'The Associated Press',
                link: 'https://amzn.to/3hBchwO',
                isFeatured: true,
              },
              {
                media: chicagoManual,
                title: 'The Chicago Manual of Style, 17th Edition',
                author: 'The University of Chicago Press Editorial Staff',
                link: 'https://amzn.to/3i6opGs',
                isFeatured: false,
              },
              {
                media: elementsStyle,
                title: 'The Elements of Style, Fourth Edition',
                author: 'William Strunk Jr. and E. B. White',
                link: 'https://a.co/d/20MrwuU',
                isFeatured: false,
              },
        ]
    },
];

const WithCtaButton = () => {
  const theme = useTheme();

  return (
    <Container paddingY={0} marginTop={8}>
        {books.map((item, i, {length}) => (
            <Box marginBottom={8} align={'center'}>
                <Box>
                    <Typography fontWeight={700} variant={'h5'} marginBottom={3}>
                        {item.sectionTitle}
                    </Typography>
                </Box> 
            <Grid container spacing={6} justifyContent='center' marginBottom={8} >
                {item.books.map((book, i) => (
                    <Grid item xs={12} sm={6} md={3} key={i}>
                    <Box display={'block'} width={1} height={1}>
                    <Card
                        sx={{
                        width: 1,
                        maxWidth: 215,
                        height: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        boxShadow: 'none',
                        bgcolor: 'transparent',
                        backgroundImage: 'none',
                        alignContent: 'center',
                        }}
                    >
                        <Link href={ book.link } target="_blank" rel="noopener" underline="none">
                            <CardMedia
                            title={book.title}
                            image={book.media}
                            sx={{
                                position: 'relative',
                                height: 320,
                                overflow: 'hidden',
                                borderRadius: 2,
                            }}
                            >
                                <Stack
                                direction={'row'}
                                spacing={1}
                                sx={{
                                position: 'absolute',
                                top: 'auto',
                                bottom: 0,
                                right: 0,
                                padding: 2,
                                }}
                            >
                                {book.isFeatured && (
                                <Box
                                    sx={{
                                    bgcolor: theme.palette.success.light,
                                    paddingY: '4px',
                                    paddingX: '8px',
                                    borderRadius: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    }}
                                >
                                    <Typography
                                    variant={'caption'}
                                    fontWeight={700}
                                    sx={{
                                        color: theme.palette.common.white,
                                        textTransform: 'uppercase',
                                        lineHeight: 1,
                                    }}
                                    >
                                    top pick
                                    </Typography>
                                </Box>
                                )}
                            </Stack>
                            </CardMedia>
                        </Link>
                        <Box marginTop={2}>
                        <Typography
                            fontWeight={700}
                        >
                            {book.title}
                        </Typography>
                        <Typography variant={'subtitle2'} color={'text.secondary'}>
                            {book.author}
                        </Typography>
                        </Box>
                    </Card>
                    </Box>
                </Grid>
                ))}
            </Grid>   
            {length-1 === i ? null : <Divider /> }
            </Box>
        ))}
        
    </Container>
  );
};

export default WithCtaButton;
