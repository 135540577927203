import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from 'components/Container';
import { Summary, Details } from './components';


const WorkshopSummary = (props) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Container>
      <Box>
        <Grid container spacing={isMd ? 8 : 4}>
          <Grid item xs={12} md={7}>
            <Summary summary={props.summary} />
          </Grid>
          <Grid item container xs={12} md={5} alignItems={'center'}>
            <Details details={props.details} />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default WorkshopSummary;