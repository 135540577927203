import { Box } from "@mui/material";
import { Typography } from "@mui/material";

const ArticleCategoryHeading = (props) => {
return(
    <Box marginBottom={4}>
        <Typography
          variant="h4"
          align={'left'}
          data-aos={'fade-up'}
          gutterBottom
          sx={{
            fontWeight: 700,
          }}
        >
          { props.title }
        </Typography>
      </Box>
)
}

export default ArticleCategoryHeading;