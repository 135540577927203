import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import logo from 'assets/images/police-writing-logo-250.png';
import { useTheme } from '@mui/material/styles';

import NavItem from './components/NavItem';

const SidebarNav = ({ pages }) => {
  const theme = useTheme();
  const { mode } = theme.palette;

  const {
    workshops: workshopPages,
    resources: resourcePages,
  } = pages;

  return (
    <Box>
      <Box width={1} paddingX={2} paddingY={1}>
        <Box
          display={'flex'}
          component={RouterLink}
          to="/"
          title="theFront"
          width={{ xs: 200, md: 200 }}
        >
          <Box
            component={'img'}
            src={
              mode === 'light'
                ? logo
                : logo
            }
            height={1}
            width={1}
          />
        </Box>
      </Box>
      <Box paddingX={2} paddingY={2}>
        <Box>
          <NavItem title={'Workshops'} items={workshopPages} />
        </Box>
        <Box paddingY={4}>
          <Link
            size={'large'}
            variant="outlined"
            fullWidth
            underline = "none"
            component={RouterLink}
            to="/reading-list"
          >
            Reading List
          </Link>
        </Box>
        <Box marginTop={1}>
          <Button
            size={'large'}
            variant="contained"
            color="primary"
            fullWidth
            component={RouterLink}
            to="/#moreinfo"
          >
            Book a Workshop
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.object.isRequired,
};

export default SidebarNav;
