/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Container from 'components/Container';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import { alpha } from '@mui/material/styles';
import { colors } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const ArticleList = (props, { colorInvert = false }) => {
  return (
    <Box>
      <Box>
        <Grid container spacing={4}>
          {props.articles.map((item, i) => (
            <Grid item xs={12} sm={6} md={3} key={i}>
              <Link
                underline="none"
                component={RouterLink}
                to={"/article/"+item.slug}
                color={colorInvert ? 'common.white' : 'text.primary'}
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <Box
                  display={'block'}
                  width={1}
                  height={1}
                  sx={{
                    textDecoration: 'none',
                    transition: 'all .2s ease-in-out',
                    '&:hover': {
                      transform: 'translateY(-4px)',
                    },
                  }}
                >
                  <Box
                    component={Card}
                    padding={4}
                    borderRadius={2}
                    width={1}
                    height={1}
                    data-aos={'fade-up'}
                    data-aos-delay={i * 100}
                    data-aos-offset={100}
                    data-aos-duration={600}
                  >
                    <Box display={'flex'} flexDirection={'column'}>
                      {/* <Box
                        component={Avatar}
                        width={60}
                        height={60}
                        marginBottom={2}
                        bgcolor={alpha(item.color, 0.1)}
                        color={item.color}
                        variant={'rounded'}
                        borderRadius={2}
                      >
                        {item.icon}
                      </Box> */}
                      <Typography
                        variant={'h4'}
                        sx={{ fontWeight: 500 }}
                      >
                        {item.shortTitle}
                      </Typography>
                      {/* <Typography color="text.secondary">
                        {item.subtitle}
                      </Typography> */}
                      <Box sx={{ flexGrow: 1 }} />
                      <Box
                        marginTop={2}
                        display={'flex'}
                        justifyContent={'flex-end'}
                      >
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default ArticleList;
