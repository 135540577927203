const pages = {
  workshops: [
    {
      title: 'Police Report Writing Fundamentals',
      href: '/workshop/fundamentals',
    },
    {
      title: '6 Rules of Effective Police Report Writing',
      href: '/workshop/six-rules',
    },
    {
      title: 'Executive Writing',
      href: '/workshop/executive-writing',
    },
  ],
  resources: [
    {
      title: 'Free Downloads',
      href: '/downloads',
    },
    {
      title: 'Reading List',
      href: '/reading-list',
    },
  ],
};

export default pages;
