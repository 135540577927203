import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const Schedule = (props) => {

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          variant="h5"
          data-aos={'fade-up'}
          align={'left'}
          gutterBottom
          sx={{
            fontWeight: 700,
          }}
        >
          Workshop Agenda
        </Typography>
      </Box>
      <Grid container spacing={4}>
      {props.agenda?.map((item, i) => (
        <Grid item xs={12} md={6} key={i}>
          <Box
            padding={{ xs: 3, md: 6 }}
            width={1}
            component={Card}
            boxShadow={4}
            data-aos="fade-up"
          >
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={{ xs: 'flex-start', sm: 'center' }}
              flexDirection={{ xs: 'column', sm: 'row' }}
            >
              <Box>
                <Typography fontWeight={700} variant={'h6'} mb={3}>
                  { item.title }
                </Typography>
                <Typography fontWeight={'normal'} variant={'body1'} sx={{lineHeight:'2em'}}>
                <div dangerouslySetInnerHTML={{__html: item.items}}/>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Schedule;
