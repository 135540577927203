import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarHalfIcon from '@mui/icons-material/StarHalf';


const FeatureHeading = (heading) => {
    if(heading){
        return(
            <Typography
              sx={{ 
                fontSize: '1.1em',
                opacity: '.75',
                marginRight: '7px',
              }}
            >
              {heading}:{'  '} 
          </Typography>
        )
    }
    else{
        return(
            "<></>"
        )
    }
}



const ClassFeature = (props) => {
    let icon = props.icon;
    let heading = props.heading;
    let title = props.title;

    return (
        <Box
          sx = {{
            display:'flex',
            alignItems:'center',
            marginBottom: '15px',
            marginTop: '15px',

          }}>
          <Box
              component="img"
              sx={{
                height: 25,
                marginRight: '20px',
              }}
              alt="clock"
              src= { icon }
            />
          <Typography
              sx={{ 
                fontSize: '1.1em',
                fontWeight:'700', 
              }}
            >
              {title}
          </Typography>
        </Box>
    );
}

export default ClassFeature;

