import React, {useState,useEffect} from 'react';
import { Link, useParams, redirect } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Main from 'layouts/Main';
import Container from 'components/Container';
import { Hero, LeadForm, Reviews, Schedule, Testimonials, WorkshopSummary } from './components';
import workshops from './workshops';
import { Navigate } from "react-router-dom";
import { Typography } from '@mui/material';


const Workshop = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const [workshop, setWorkshop] = useState('');
  let { title } = useParams();

  useEffect(() => {
    if(title == 'six-rules'){
      setWorkshop(workshops.sixRules);
    }
    else if(title == 'fundamentals'){
      setWorkshop(workshops.fundamentals);
    }
    else if(title == 'executive-writing'){
      setWorkshop(workshops.executive);
    }
    else{
      window.location.replace("/404");
    }
  },[workshop])

  return (
    <Main>
      <Box bgcolor={'alternate.main'} marginTop={-13} paddingTop={13}>
        <Container>
          <Hero title={workshop.title} subtitle={workshop.subtitle} photo={workshop.photo} />
        </Container>
      </Box>
      <WorkshopSummary summary={workshop.summary} details={workshop.details} />
      <Box
        position={'relative'}
        sx={{
          backgroundColor: theme.palette.alternate.main,
        }}
      >
        <Container>
          <Schedule agenda={workshop.agenda} />
        </Container>
      </Box>
        { workshop.reviews ? <Testimonials reviews={workshop.reviews} /> : "" }
      <Box
        position={'relative'}
        sx={{
          backgroundColor: workshop.reviews ? theme.palette.alternate.main : "",
        }}
      >
        <Container>
          <LeadForm />
        </Container>
        <Box
          component={'svg'}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 100.1"
          sx={{
            width: '100%',
            marginBottom: theme.spacing(-1),
          }}
        >
          <path
            fill={theme.palette.background.paper}
            d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
          ></path>
        </Box>
      </Box>
    </Main>
  );
};

export default Workshop;