/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';

const Summary = () => {
  const theme = useTheme();
  return (
    <Box>
      <Box>
        <Box marginBottom={1}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 700,
              mb:2,
            }}
          >
            Host a writing workshop designed specifically for law enforcement.
          </Typography>
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={11}>
          <Typography
            component={'p'}
            variant={'body1'}
            gutterBottom
            sx={{ fontWeight: 'medium', opacity:.85, fontSize:'1.05em' }}
          >
            Whether you are training new recruits, seasoned officers, sworn employees, or civilian support staff, we can customize our workshops for nearly any audience or budget.
            <br /><br />
            Need one-on-one instruction or coaching? Want someone to review your written materials for clarity? We also offer professional writing and editing services to make your policies, procedures, handbooks, or other written material more effective.
            <br /><br />
            Tell us a little about your training needs and a team member will contact you soon to discuss more details.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Summary;
