import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Main from 'layouts/Main';
import Container from 'components/Container';
import { collection, getDocs, query, where } from "firebase/firestore"; 
import { db } from '../../firebase';
import {
  ArticleCategoryHeading,
  ArticleList,
  FeaturedArticles,
  Hero,
  MostViewedArticles,
  SidebarNewsletter,
} from './components';

async function firebaseFetchArticles(){
  const queryResults = [];
  const q = query(collection(db, "articles"), where("draft", "==", false));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    queryResults.push({
      ...doc.data(),
      key:doc.id,
    });
  });
  return queryResults;
}

const Articles = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const [articles, setArticles] = useState([]);


  useEffect(() => {
    // Fetch all articles
    const fetchArticles = async() => {
      const allArticles = await firebaseFetchArticles();
      setArticles(allArticles);
    }
    fetchArticles();
  }, [])

  return (
    <Main>
      <Box bgcolor={'alternate.main'} marginTop={-13} paddingTop={13}>
        <Container>
          <Hero />
        </Container>
      </Box>
      <Box>
        <Container>
           <FeaturedArticles />
        </Container>
      </Box>
      <Box>
        <Container marginBottom="0">
          <Grid container spacing={isMd ? 4 : 0}>
            <Grid item xs={12} md={8}>
              <MostViewedArticles />
            </Grid>
            <Grid item xs={12} md={4}>
              <SidebarNewsletter />
            </Grid>
          </Grid>
        </Container>
        <Container>
          <ArticleCategoryHeading title="Grammar" />
          <ArticleList articles={articles} />
        </Container>
      </Box>
    </Main>
  );
};

export default Articles;
