/* eslint-disable react/no-unescaped-entities */
import React, {useState} from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { Chip } from '@mui/material';
import Grid from '@mui/material/Grid';
import { httpsCallable } from "firebase/functions";
import { functions } from '../../../../firebase';
import { CheckCircle } from '@mui/icons-material';

const validationSchema = yup.object({
  firstName: yup
    .string()
    .trim()
    .min(2, 'Please enter a valid name')
    .max(50, 'Please enter a valid name')
    .required('This field is required.'),
  lastName: yup
    .string()
    .trim()
    .min(2, 'Please enter a valid name')
    .max(50, 'Please enter a valid name')
    .required('This field is required.'),
  email: yup
    .string()
    .trim()
    .email('Please enter a valid email address')
    .required('This field is required.'),
  agency: yup
    .string()
    .trim(),
  jobTitle: yup
    .string()
    .trim()
    .min(2, 'Please enter a valid job title')
    .max(50, 'Please enter a valid job title')
    .required('This field is required.'),
  phoneNumber: yup
    .string()
    .trim()
    .min(10, 'Please enter a valid phone number')
    .max(20, 'Please enter a valid phone number')
    .required('This field is required.'),
  message: yup
    .string()
    .trim()
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const interestsList = [
  '6 Rules of Effective Police Report Writing',
  'Police Report Writing Fundamentals', 
  'Executive Writing',
  'Individual Writing Coaching/Instruction',
  'Professional Writing/Editing Services'
];

const sendInfoRequestConfirmationEmail = httpsCallable(functions, 'sendInfoRequestConfirmationEmail');

const Form = () => {
  const theme = useTheme();
  const [interest, setInterest] = useState([]);
  const [sending, setSending] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);


  const initialValues = {
    firstName: '',
    lastName: '',
    jobTitle: '',
    agency: '',
    phoneNumber: '',
    email: '',
    message: '',
  };

  const handleChange = (event) =>{
    let formValue = event.target.value;
    setInterest( formValue === 'string' ? formValue.split(',') : formValue);
  }

  const onSubmit = (values) => {
    setSending(true);
   
    // Handle API Call
    sendInfoRequestConfirmationEmail({
      'firstName' : values.firstName,
      'lastName'  : values.lastName,
      'jobTitle'  : values.jobTitle,
      'agency'    : values.agency,
      'phoneNumber' : values.phoneNumber,
      'email'     : values.email,
      'message'   : values.message,
      'interest' : interest.join("<br />"),
    })
    .then((result) => {
      setFormSubmitted(true);
      setSending(false);
    });
    return values;
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <Box>
      <Box
        padding={{ xs: 3, sm: 6 }}
        width={1}
        component={Card}
        boxShadow={1}
        marginBottom={4}
        sx={{ display: formSubmitted===true ? '' : 'none' }}
      >
        <Box
          display = 'flex'
          justifyContent = 'center'
        >
          <CheckCircle sx={{
            color: theme.palette.primary.light,
            fontSize: '55px',
            marginBottom: '15px',
          }} />
        </Box>
        <Typography align='center' fontSize='1.2em'>
          Thank you for your request.<br />A member of our team will be in touch with your shortly.
        </Typography>
      </Box>
      <Box
        padding={{ xs: 3, sm: 6 }}
        width={1}
        component={Card}
        boxShadow={1}
        marginBottom={4}
        sx={{ display: formSubmitted===true ? 'none' : '' }}
      >
        <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ height: 54 }}
                label="First name"
                variant="outlined"
                color="primary"
                size="medium"
                name="firstName"
                fullWidth
                required
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ height: 54 }}
                label="Last name"
                variant="outlined"
                color="primary"
                size="medium"
                name="lastName"
                fullWidth
                required
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ height: 54 }}
                label="Job Title"
                variant="outlined"
                color="primary"
                size="medium"
                name="jobTitle"
                fullWidth
                required
                value={formik.values.jobTitle}
                onChange={formik.handleChange}
                error={
                  formik.touched.jobTitle && Boolean(formik.errors.jobTitle)
                }
                helperText={formik.touched.jobTitle && formik.errors.jobTitle}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ height: 54 }}
                label="Phone Number"
                variant="outlined"
                color="primary"
                size="medium"
                name="phoneNumber"
                fullWidth
                required
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
                error={
                  formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
                }
                helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                sx={{ height: 54 }}
                label="Work Email"
                type="email"
                variant="outlined"
                color="primary"
                size="medium"
                name="email"
                fullWidth
                required
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                sx={{ height: 54 }}
                label="Agency/Academy Name"
                variant="outlined"
                color="primary"
                size="medium"
                name="agency"
                fullWidth
                value={formik.values.agency}
                onChange={formik.handleChange}
                error={formik.touched.agency && Boolean(formik.errors.agency)}
                helperText={formik.touched.agency && formik.errors.agency}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl sx={{ width: 1 }}>
                <InputLabel id="interests-selector" sx={{ minWidth: 140, backgroundColor:'white' }}>I'm interested in...</InputLabel>
                <Select
                  labelId="interests-selector"
                  id="interests-multiple-checkbox"
                  multiple
                  value={interest}
                  onChange={handleChange}
                  input={<OutlinedInput label="interested" />}
                  // renderValue={(selected) => selected.join(', ')}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {interestsList.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={interest.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Describe Your Training Needs"
                multiline
                rows={4}
                variant="outlined"
                color="primary"
                size="medium"
                name="message"
                fullWidth
                value={formik.values.message}
                onChange={formik.handleChange}
                error={formik.touched.message && Boolean(formik.errors.message)}
                helperText={formik.touched.message && formik.errors.message}
              />
            </Grid>
            <Grid item container justifyContent={'center'} xs={12}>
              <Button
                sx={{ height: 54, minWidth: 150 }}
                variant="contained"
                color="primary"
                size="medium"
                type="submit"
                fullWidth
                disabled = { sending ? true : false }
              >
                { sending ? "Sending..." : "Send Request" }
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  );
};

export default Form;
