/* eslint-disable react/no-unescaped-entities */
import Markdown from 'markdown-to-jsx';
import React from 'react';
import { useParams } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from 'components/Container';

const ArticleContent = (props) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const { title } = useParams();

  return (
    <Container 
      sx={{
        position: 'relative',
        '&::after': {
          position: 'absolute',
          content: '""',
          width: '20%',
          zIndex: 1,
          top: 0,
          left: 0,
          height: '100%',
          opacity: 0.2,
        },
      }}
    >
      <Box position={'relative'} zIndex={2}>
        <Box>
          <Typography
            variant="h5"
            color="text.primary"
            align={'left'}
            sx={{
              fontWeight: 600,
              mb:2,
            }}
          >
            { props.teaser }
          </Typography>
            <Markdown>
              { props.body }
            </Markdown>
        </Box>
        <Box marginBottom={2}>
          <Typography
            variant="h4"
            color="text.primary"
            align={'left'}
            sx={{
              fontWeight: 700,
              mb:2,
            }}
          >
            Section Title
            <br />
            
          </Typography>
          <Typography
            component="p"
            color="text.primary"
            sx={{ 
                fontWeight: 400, 
                opacity: .9,
                fontSize: '1.3em',
                lineHeight:'1.6em',
            }}
            align={'left'}
          >
            Build a beautiful, modern website with flexible, fully customizable,
            atomic MUI components.Build a beautiful, modern website with flexible, fully customizable,
            atomic MUI components.Build a beautiful, modern website with flexible, fully customizable,
            atomic MUI components.Build a beautiful, modern website with flexible, fully customizable,
            atomic MUI components.Build a beautiful, modern website with flexible, fully customizable,
            atomic MUI components.Build a beautiful, modern website with flexible, fully customizable,
            atomic MUI components.Build a beautiful, modern website with flexible, fully customizable,
            atomic MUI components.Build a beautiful, modern website with flexible, fully customizable,
            atomic MUI components.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default ArticleContent;
