import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Main from 'layouts/Main';
import Container from 'components/Container';
import {
    ArticleContent,
  Hero,
} from './components';

import { db } from '../../firebase';
import { doc, getDoc } from "firebase/firestore"; 

const Article = () => {
  const [article, setArticle] = useState({
    title: '',
    teaser: '',
    body: '',
  });
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  let { slug } = useParams();
  const docRef = doc(db, "articles", "should-i-use-the-oxford-comma");
  
  useEffect(() => {
    // Fetch selected article
    const fetchArticle = async() => {
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setArticle(docSnap.data());
      } else {
        // doc.data() will be undefined in this case
        // 404 Error redirect
        console.log("No such document!");
      }
    }
    fetchArticle();
  }, [])

  return (
    <Main>
      <Box bgcolor={'alternate.main'} marginTop={-13} paddingTop={13}>
        <Container>
          <Hero title={article.title} teaser={article.teaser}/>
        </Container>
      </Box>
      <Box paddingTop={3}>
        <ArticleContent body={article.body} teaser={article.teaser} />
      </Box>
    </Main>
  );
};

export default Article;
