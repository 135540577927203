/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { colors } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import BookImage from '../../../../assets/images/book-render-md-plain.png';
import PurchaseAmazon from '../../../../assets/images/purchase-amazon.png';


const FeatureBook = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box
      padding={{ xs: 2, sm: 4 }}
      borderRadius={2}
      bgcolor={
        theme.palette.mode === 'light' ? colors.blue[50] : colors.blue[900]
      }
    >
      <Grid
        container
        spacing={isMd ? 4 : 2}
        flexDirection={{ xs: 'column-reverse', md: 'row' }}
      >
        <Grid item xs={12} md={7}>
          <Grid container spacing={isMd ? 4 : 2}>
            <Grid item
              xs={12}
            >
              <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={ 'center' }
              >
                <Link href='https://a.co/d/4t7uMf4' target="_blank" rel="noopener" underline="none">
                  <Box
                    component={'img'}
                    loading="lazy"
                    src={BookImage}
                    alt="..."
                  />
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={ 'flex-start' }
            height={1}
          >
            <Box>
              <Typography variant={'h4'} fontWeight={700} gutterBottom>
                Tell the <em>right people</em> the <em>right information </em>in the <em>clearest way</em> possible  
              </Typography>
              <Typography
                color={
                  theme.palette.mode === 'light'
                    ? 'text.secondary'
                    : 'text.primary'
                }
              >
                Part instructional guide, part reference manual, <em>Police Report Writing: The essential guide to crafting effective police reports</em> gives you proven techniques for communicating the details of even the most complex police incidents to your readers.
              </Typography>
            </Box>
            <Link href='https://a.co/d/4t7uMf4' target="_blank" rel="noopener" underline="none">
            <Box
                component={'img'}
                loading="lazy"
                src={PurchaseAmazon}
                alt="Amazon Link"
                borderRadius={2}
                marginTop={2}
                width={{ xs: 200, md: 240 }}
              />
              </Link>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FeatureBook;
