import React from 'react';
import Box from '@mui/material/Box';
// import OfficerComputer from '../../../../../../assets/images/';

const CoverSlider = (props) => {
  return (
    <Box
      sx={{
        height: { xs: 'auto', md: 1 },
        '& img': {
          objectFit: 'cover',
        },
      }}
    >

      <Box
        component={'img'}
        src={'/images/'+ props.image }
        height={{ xs: 'auto', md: 1 }}
        maxHeight={{ xs: 300, md: 1 }}
        maxWidth={1}
      />

    
    </Box>
    
  );
};

export default CoverSlider;
