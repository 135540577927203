/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from 'components/Container';
import Stars from './components/Stars';

const Testimonials = (props) => {
  const theme = useTheme();
  const totalReviews = props.reviews.length;
  const [reviewCount, setReviewCount] = useState(4);
  const [reviewButtonVisible, setReviewButtonVisible] = useState(true); // Change the display state of the 'load more reviews' button

  const increaseReviewCounter = () =>{
    let reviewLimit = 0;
    reviewCount+5 >= totalReviews ? reviewLimit = totalReviews : reviewLimit = reviewCount+5;
    setReviewButtonVisible(reviewLimit >= totalReviews ? false : true);
    setReviewCount(reviewLimit);
  }

  return (
    <Box bgcolor={ props.background ? 'alternate.main' : '' } paddingY={8}>
      <Typography
            variant="h4"
            align={'center'}
            gutterBottom
            sx={{
              fontWeight: 700,
            }}
          >
            Workshop Reviews
          </Typography>
      <Container paddingY={0} maxWidth='725px'>
        {props.reviews?.slice(0,reviewCount).map((item, i) => (
          <Box display={'flex'} flexDirection={'column'} alignItems={'center'} paddingY={5} key={i}>
            <Stars rating={ item.rating } total={false} />
            <Typography component={'p'} fontSize='1.2em' fontWeight={700} align={'center'}>
              <div dangerouslySetInnerHTML={{__html: item.review}} />
            </Typography>
            <Box marginTop={1}>
              <Typography
                variant={'h6'}
                sx={{ fontWeight: 700 }}
                align={'center'}
              >
              </Typography>
              <Typography color="text.secondary" align={'center'}>
              { item.segment }, { item.tenure }
              </Typography>
            </Box>
          </Box>
        ))}
        <Box display={'flex'} alignItems={'center'} flexDirection={'column'} >
          <Button variant='outlined' color='primary' onClick={increaseReviewCounter} sx={{ display: reviewButtonVisible ? '' : 'none' }}>Load More Reviews</Button>
        </Box>
      </Container>
    </Box>
  );
};

export default Testimonials;
