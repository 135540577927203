const workshops = {
    fundamentals: {
        title: 'Police Report Writing Fundamentals',
        subtitle: 'An introduction to the very basics of police report writing.',
        photo: 'officer-man.jpg',
        summary: `It's estimated that officers spend between 30% and 50% of their time writing police reports during a given shift.
        <br /><br />
        This workshop gives academy recruits the fundamental knowledge, tools, and skills they need to write police reports for basic incidents. Following an instruction block, students will participate in a practical exercise and a writing workshop.
        <br /><br />
        Since academy curriculums are usually influenced by state requirements, we'll customize the workshop to ensure your recruits fullfill the mandated criteria. Additionally, we can provide basic instruction and support materials to your academy staff so you're able to assist your recruits after the workshop ends.
        `,
        details:{
            audience: ['Academy Recruits','Brand New Officers','Those with no experience writing police reports'],
            length: '6 – 8 hours',
            format: 'In Person',
            writingExercises: true,
            writtenFeedback: false,
            quickReference: true,
        },
        agenda: [
            {
                title: 'Day One',
                items: `
                    Introduction & Course Overview <br />
                    Why Police Reports?<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;<i>What are the 6 Purposes of Police Reports?</i><br />
                    What is a Police Report?<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;<i>What does a police report look like?</i><br />
                    &nbsp;&nbsp;&nbsp;&nbsp;<i>What are the parts of a police narrative?</i><br />
                    &nbsp;&nbsp;&nbsp;&nbsp;<i>What information is included in a police report?</i><br />
                    How Do I Write Effective Police Reports?<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;<i>What is the process to write effectively each time?</i><br />
                    Practical Exercise<br />
                    Report Writing, Editing, & Peer Review
                `,
            },
        ],
    },          
    sixRules: {
        title: '6 Rules of Effective Police Report Writing',
        subtitle: 'Learn to write police reports that tell the right people the right information in the clearest way possible.',
        photo: 'officer-computer.jpeg',
        summary: `This two-day instructional workshop is designed for every officer or supervisor who wants to write clearer, more effective reports in less time. 
                <br /><br /> 
                Students will learn an easy-to-follow system that progresses from foundational principles to practical application. Each student will practice the 6 Rules and receive personalized feedback from the instructor and their fellow classmates. Students will also receive quick-reference materials that they can use to easily apply what they learn in their current assignment.
                <br /><br />
                <span style="font-weight:700">Students will learn to write reports that:</span>
                <ul>
                    <li>Document the relevant facts of the incident</li>
                    <li>Omit unnecessary information</li>
                    <li>Satisfy the requirements of their supervisors, attorneys, and other readers</li>
                    <li>Accurately record statements made by suspects and witnesses</li>
                    <li>Organize events using the 5 Parts of a Police Narrative</li>
                    <li>Use concrete, unambiguous language</li>
                    <li>Avoid common grammatical errors</li>
                </ul>
                `,
        details:{
            overallRating: 4.9,
            audience: ['Patrol Officers','Patrol Supervisors','Detectives','Anyone who writes or reads police reports.'],
            length: 'Two Days',
            format: 'In Person',
            writingExercises: true,
            writtenFeedback: true,
            quickReference: true,
        },
        agenda: [
            {
                title: 'Day One',
                items: `
                    Introduction & Course Overview <br />
                    Foundations of Report Writing <br />
                    Rule #1: Identify Your Audience <br />
                    Rule #2: Include the Relevant <br />
                    Lunch <br />
                    Writing Exercise <br />
                    Rule #3: Guide the Reader <br />
                    Workshop <br />
                    Daily Wrap-Up <br />
                    Dismissal
                `,
            },
            {
                title: 'Day Two',
                items: `
                    Rule #4: Let the Facts Speak <br />
                    Rule #5: Make Words Work <br />
                    Rule #6: Correct for Clarity <br />
                    Lunch <br />
                    Workshop <br />
                    Course Wrap-Up <br />
                    Dismissal <br />
                `,
            },
            
        ],
        reviews: [
            {
                review: 'Best class that I have taken about report writing. Great overall structure and good training material.',
                segment: 'Supervisor',
                tenure: '11–15 Years',
                rating: '5',
            },
            {
                review: 'The course was well organized and engaging. The hands on work allowed me to understand and incorporate the concepts learned in the course.',
                segment: 'Patrol',
                tenure: '2–5 Years',
                rating: '5',
            },
            {
                review: 'Amazing class, the instructor is very professional, always prepared, and very supportive regardless of your writing skills.',
                segment: 'Patrol',
                tenure: '0–1 Years',
                rating: '5',
            },
            {
                review: 'New methods to properly write a report. Will definitely implement these tools.',
                segment: 'Patrol',
                tenure: '15+ Years',
                rating: '5',
            },
            {
                review: 'Good class. Enthusiastic way of presenting the information. The game was fun too.',
                segment: 'Supervisor',
                tenure: '11–15 Years',
                rating: '5',
            },
            {
                review: 'Thank you for everything!',
                segment: 'Patrol',
                tenure: '0–1 Years',
                rating: '5',
            },
            {
                review: 'I feel more confident about writing a more organize and detail report.',
                segment: 'Patrol',
                tenure: '0-1 Years',
                rating: '5',
            },
            {
                review: 'The course was great, guides and reference materials are very useful, course information was easy to follow',
                segment: 'Corrections, Booking/Processing',
                tenure: '5–10 Years',
                rating: '5',
            },
            {
                review: 'It was great. Honestly, best class.',
                segment: 'Patrol',
                tenure: '0–1 Years',
                rating: '5',
            },
            {
                review: 'Excellent course. Very well thought out and organized. Instructor is very knowledgeable. Suggestion to mention that report writing starts with good questioning/interviewing/investigation, listening, and note taking. Thank you.',
                segment: 'Police Records Section',
                tenure: '15+ Years',
                rating: '5',
            },
            {
                review: 'It\'s was a great class, I learned how important is to write a good report.',
                segment: 'Deputy',
                tenure: '2–5 Years',
                rating: '5',
            },
            {
                review: 'I really enjoyed the grammar review. I struggle a lot with punctuation. I have never figured it out even though I graduated college with a BA. Rule #4 is my favorite. I already kind of already write in chronological order. Using bullet points will be super helpful. I never thought it was Ok to write I see or I smelled. I always used noticed etc.',
                segment: 'Patrol',
                tenure: '2–5 Years',
                rating: '4.5',
            },
            {
                review: 'This course simplified the steps necessary to execute and document a call properly into a reported format',
                segment: 'Patrol',
                tenure: '2–5 Years',
                rating: '5',
            },
            {
                review: 'Great class take sooner rather than later. Extremely valuable information.',
                segment: 'Patrol Services Division',
                tenure: '0–1 Years',
                rating: '5',
            },
            
        ]
    },          
    executive: {
        title: 'Executive Writing',
        subtitle: 'Business writing fundamentals for law enforcement professionals',
        photo: 'women-computer.jpeg',
        summary: `<strong>Learn to express your ideas more effectively with this one-day workshop.</strong>
                <br /><br /> 
                No one wants to spend their time and energy writing something, only to have it completely ignored or misunderstood by their reader. This crash course teaches you a four-step, reader-centric approach to business writing. Whether you’re a supervisor, an officer, or a non-sworn employee, you’ll learn best practices for getting your point across through email correspondence, proposals, policies, internal memos, press releases, and other written documents.
                <br /><br />
                <span style="font-weight:700">Students will learn:</span>
                <ul>
                    <li>The essential ingredients in all business writing.</li>
                    <li>Various writing structures and when to use them.</li>
                    <li>How to style your writing so that your reader will listen.</li>
                    <li>How to avoid (and fix) common grammar and usage errors.</li>
                    <li>Business writing tips from real-world corporate examples.</li>
                    <li>The four stages of business writing: gathering ideas, organizing ideas, expressing ideas, and clarifying ideas.</li>
                </ul>
                `,
        details: {
            overallRating: 4.9,
            audience: ['Supervisors','Command Staff','Chiefs & Sheriffs','Administrative Supervisors','Administrative Staff','Anyone who writes or reads email, memos, proposals, or other business documents on a consistent basis.'],
            length: 'One Day',
            format: 'In Person',
            writingExercises: true,
            writtenFeedback: true,
            quickReference: true,
        },        
        agenda: [
            {
                title: 'Day One',
                items: `
                    Introduction & Course Overview <br />
                    Four Elements of Effective Business Writing <br />
                    Four Stages of Writing: Pre-Writing <br />
                    Four Stages of Writing: Organizing <br />
                    Four Stages of Writing: Writing <br />
                    Lunch <br />
                    Writing Exercises <br />
                    Four Stages of Writing: Revising/Editing <br />
                    Group Exercise <br />
                    Class Wrap-up
                `,
            },
            
        ],
        reviews: [
            {
                review: 'This is one of the best writing course I have taken.',
                segment: 'Commander, Patrol Operations',
                tenure: '15+ Years',
                rating: '5',
            },
            {
                review: 'Writing professionally is a perishable skill. You take so many shortcuts with emails & other forms of informal communication that you often forget the rules. Great information & thanks for the resources!',
                segment: 'Asst. Chief/Patrol Division Commander',
                tenure: '15+ Years',
                rating: '5',
            },
            {
                review: 'This was EXACTLY what I wanted!!! A class focused on structure and style of writing and really helped me to improve my writing and thought process!! Instructor was born to teach this (I mean that in the best way!) Engaging, knowledgeable, and enthusiastic!!',
                segment: 'Training/admin',
                tenure: '5-10 Years',
                rating: '5',
            },
            {
                review: '',
                segment: 'Patrol Supervisor',
                tenure: '11–15 Years',
                rating: '4.5',
            },
            {
                review: 'Thank you very much for the <i>what</i> and <i>so what</i> as well as the <i>2 AM Rule</i>. Class is extremely insightful. Would definitely recommend to my agency having you host your writing class.',
                segment: 'Supervisor',
                tenure: '15+ Years',
                rating: '5',
            },
            {
                review: 'I really enjoyed the information provided. Even after writing professionally for so long, there was information to learn and take away.',
                segment: 'Administrator',
                tenure: '15+ Years',
                rating: '5',
            },
            {
                review: 'Enjoyed the instructor. Refreshed some subjects and definitely introduced me to others. Thank you',
                segment: 'Supervisor',
                tenure: '15+ Years',
                rating: '5',
            },
            {
                review: '',
                segment: 'Manager',
                tenure: '11–15 Years',
                rating: '5',
            },
            {
                review: 'I would be very interested in a regional style guide. My background in APA format instilled a very robotic diction so the thought of using contractions in professional writing is unconscionable.',
                segment: 'Investigations',
                tenure: '5–10 Years',
                rating: '5',
            },
            {
                review: 'Great information',
                segment: 'Administración',
                tenure: '2–5 Years',
                rating: '',
            },
            {
                review: 'The longer the class, the more effective. Make it 2-3 days!',
                segment: 'Sergeant',
                tenure: '11–15 Years',
                rating: '4.5',
            },
            {
                review: 'The information is very helpful.',
                segment: 'Jail officer',
                tenure: '5–10 Years',
                rating: '5',
            },
            {
                review: 'You are a great instructor that provided a lot of knowledge. Thanks!',
                segment: 'Supervisor',
                tenure: '15+ Years',
                rating: '5',
            },
            {
                review: 'Was truly a great class and I believe it will instantly help me improve my writing and career. I will recommend that this class is taught to my agency as a whole.',
                segment: 'Lieutenant',
                tenure: '11–15 Years',
                rating: '5',
            },
            {
                review: 'Very interesting and informative.',
                segment: 'Patrol',
                tenure: '2–5 Years',
                rating: '5',
            },
            {
                review: 'Needs to be longer',
                segment: 'SRO',
                tenure: '2–5 Years',
                rating: '4.5',
            },
            {
                review: '',
                segment: 'Patrol',
                tenure: '5–10 Years',
                rating: '5',
            },
            {
                review: 'I thought this was a great course. I find myself having to write many documents that are ultimately sent to people outside of our department. I would even take again!',
                segment: 'Patrol',
                tenure: '11-15 Years',
                rating: '5',
            },
            {
                review: 'Learning good formatting for emails, memos and policy was extremely helpful. Going over common grammar usages was also very helpful. I enjoyed this class and the instructor was very knowledgeable.',
                segment: 'Patrol Supervisor',
                tenure: '5–10 Years',
                rating: '5',
            },
            {
                review: 'Excellent course. Extremely relevant to the job. Instructor was knowledgeable and made the material easy to follow. I recommend the course.',
                segment: 'Patrol',
                tenure: '5–10 Years',
                rating: '5',
            },
        ],
    }
  };
  
  export default workshops;
  