import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import Main from 'layouts/Main';
import Container from 'components/Container';
import { Form } from './components';

const SigninSimple = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Main>
      <Container display="flex" justifyContent='center'>
        <Box 
          maxWidth={{
            sm:'100%', 
            md:'50%'
          }}
        >
          <Form />
        </Box>
      </Container>
    </Main>
  );
};

export default SigninSimple;
