import React from 'react';
import Main from 'layouts/Main';
import Container from 'components/Container';
import { Books, FeatureBook, Hero} from './components';

const PortfolioGrid = () => {
  return (
    <Main>
      <Container paddingBottom={0} marginBottom={0}>
        <Hero />
      </Container>
      <Container paddingY={0} marginBottom={10}>
        <FeatureBook />
      </Container>
      <Container paddingY={'0 !important'}>
        <Books />
      </Container>
    </Main>
  );
};

export default PortfolioGrid;
