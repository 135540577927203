import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarHalfIcon from '@mui/icons-material/StarHalf';

const Stars = (props) => {
    let rating = props.rating;
    let roundedStars = Math.round(rating / 0.5) * 0.5;
    let wholeStars = Math.floor(roundedStars);
    let halfStars = roundedStars-wholeStars;
    let wholeStarsArray = [];
    let halfStarsArray = [];

    for (let i = 1; i < wholeStars+1; i++){
        wholeStarsArray.push(i)
    }
    for (let i = 1; i < halfStars+1; i++){
        halfStarsArray.push(i)
    }

    return (
        <Box
          sx = {{
            display:'flex',
            alignItems:'center',
            marginTop: '5px',
            marginBottom: '10px',

          }}>
            {wholeStarsArray.map(i => (
                <StarIcon sx={{ color:'#ffc107'}} key={i}/>
            ))}
            {halfStarsArray.map(i => (
                <StarHalfIcon sx={{ color:'#ffc107'}} key={i}/>   
            ))}
            <Typography
            sx={{
                opacity:'.85',
                fontSize:'1em',
            }}>
            </Typography>
        </Box>
    );
}

export default Stars;

